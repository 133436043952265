export const DayOne = [
  {
    name: 'Registration',
    isBreak: true,
    startTime: '2023-09-25T15:15+02:00',
    panelLength: '45',
    icon: 'register'
  },

  {
    name: 'Opening of the Day',
    isBreak: true,
    startTime: '2023-09-25T16:00+02:00',
    panelLength: '10',
    icon: 'opening'
  },

  {
    name: 'European Funds for Innovation: R&D and Effective Business-Science Collaboration in the Example of Mazovia',
    isBreak: false,
    startTime: '2023-09-25T16:10+02:00',
    panelLength: '50',
    eventSpeakers: 'Mariusz Frankowski',
    activityType: 'Lecture'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-25T17:00+02:00',
    panelLength: '10',
    icon: 'coffee'
  },

  {
    name: 'The art of Writing Grant Applications: From Idea to Effective Proposal',
    isBreak: false,
    startTime: '2023-09-25T17:10+02:00',
    panelLength: '50',
    eventSpeakers: 'Anna Koteja PhD',
    activityType: 'Lecture'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-25T18:00+02:00',
    panelLength: '10',
    icon: 'coffee'
  },

  {
    name: 'Practical Tools for Applicants: How to Prepare Goals, Budget, and Showcase Team Competencies',
    isBreak: false,
    startTime: '2023-09-25T18:10+02:00',
    panelLength: '50',
    eventSpeakers: "Michał Pietras",
    activityType: 'Lecture'

  },
  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-25T19:10+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'Intellectual Property Protection in Science: How to Publish Without Exposing Your Innovations',
    isBreak: false,
    startTime: '2023-09-25T19:20+02:00',
    panelLength: '60',
    eventSpeakers: 'Piotr Zakrzewski',
    activityType: 'Lecture'

  },
  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-25T20:10+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'From grants for basic research to commercialization',
    isBreak: false,
    startTime: '2023-09-25T20:20+02:00',
    panelLength: '30',
    eventSpeakers: 'Paweł Płatek',
    activityType: 'Lecture'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-25T20:50+02:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Registration',
    isBreak: true,
    startTime: '2023-09-25T09:00+02:00',
    panelLength: '25',
    icon: 'register'
  },

  {
    name: 'Opening of the Day',
    isBreak: true,
    startTime: '2023-09-25T09:25+02:00',
    panelLength: '5',
    icon: 'opening',
  },
  {
    name: 'Commercialization Reactor - Bringing Meaningful Startups to the Global Market',
    isBreak: false,
    startTime: '2023-09-25T09:30+02:00',
    panelLength: '75',
    eventSpeakers: 'Ilona Gulchak, Tom Slapjums',
    activityType: 'Lecture'

  },
  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-20T10:45+02:00',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'How can universities and institutes support you in starting your own business? Technological transfer',
    isBreak: false,
    startTime: '2023-09-25T11:00+02:00',
    panelLength: '60',
    eventSpeakers: 'Panelists: Judyta Sobczyk, Maciej Klaczyński, Robert Dwiliński, Magdalena Bohusz-Boguszewska | Moderator: Anna Zimny-Zając',
    activityType: 'Panel'

  },
  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-20T12:00+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Bridges of Innovation: Cooperation between Science and Business from the Perspective of Entrepreneurs, Corporations, and State-Owned Companies',
    isBreak: false,
    startTime: '2023-09-25T12:15+02:00',
    panelLength: '70',
    eventSpeakers: 'Panelists: Anna Kowalczuk PhD, Magdalena Tyszkiewicz, Marek Garniewski, Natalia Osica, Mariusz Szmigiel | Moderator: Anna Zimny-Zając',
    activityType: 'Panel'

  },
  {
    name: 'Break',
    isBreak: true,
    startTime: '2023-09-20T13:25+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'Scientific Presentation and Sales Skills, Scientific Marketing as a New Marketing Path',
    isBreak: false,
    startTime: '2023-09-25T13:35+02:00',
    panelLength: '75',
    eventSpeakers: 'Bohdan Pawłowicz',
    activityType: 'Workshop'
  },
  {
    name: 'Lunch Break',
    isBreak: true,
    startTime: '2023-09-25T14:50+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Success Stories of Those Who Successfully Combined Science with Business',
    isBreak: false,
    startTime: '2023-09-25T15:35+02:00',
    panelLength: '75',
    eventSpeakers: 'Panelists: Jacek Kosiec, Prof. Jacek Jemielity, Stanisław Giziński | Moderator: Przemysława Białkowski',
    activityType: 'Panel'

  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-25T16:50+02:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const AgendaFSTSEN = [DayOne, DayTwo]