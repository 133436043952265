export const DayOne = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-25T15:15+02:00',
    panelLength: '45',
    icon: 'register'
  },

  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-25T16:00+02:00',
    panelLength: '10',
    icon: 'opening'
  },

  {
    name: 'Fundusze Europejskie na Innowacje: R&D i Skuteczna Współpraca Biznesu z Nauką na przykładzie Mazowsza',
    isBreak: false,
    startTime: '2023-09-25T16:10+02:00',
    panelLength: '50',
    eventSpeakers: 'Mariusz Frankowski',
    activityType: 'Prelekcja'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-25T17:00+02:00',
    panelLength: '10',
    icon: 'coffee'
  },

  {
    name: 'Sztuka pisania wniosków grantowych: od pomysłu do skutecznego wniosku',
    isBreak: false,
    startTime: '2023-09-25T17:10+02:00',
    panelLength: '50',
    eventSpeakers: 'dr Anna Koteja',
    activityType: 'Prelekcja'

  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-25T18:00+02:00',
    panelLength: '10',
    icon: 'coffee'
  },

  {
    name: 'Praktyczne narzędzia dla wnioskodawców: jak przygotować cele, budżet i zaprezentować kompetencje zespołu',
    isBreak: false,
    startTime: '2023-09-25T18:10+02:00',
    panelLength: '50',
    eventSpeakers: "Michał Pietras",
    activityType: 'Prelekcja'
  },
  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-25T19:00+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'Ochrona własności intelektualnej w nauce: Jak publikować, nie narażając swoich innowacji',
    isBreak: false,
    startTime: '2023-09-25T19:10+02:00',
    panelLength: '60',
    eventSpeakers: 'Piotr Zakrzewski',
    activityType: 'Prelekcja'
  },
  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-25T20:10+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'Od grantów na badania podstawowe po komercjalizację',
    isBreak: false,
    startTime: '2023-09-25T20:20+02:00',
    panelLength: '30',
    eventSpeakers: 'Paweł Płatek',
    activityType: 'Prelekcja'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-25T20:50+02:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-25T09:00+02:00',
    panelLength: '25',
    icon: 'register'
  },

  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-25T09:25+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Commercialization reactor - bringing meaningful startups to the Global market',
    isBreak: false,
    startTime: '2023-09-25T09:30+02:00',
    panelLength: '75',
    eventSpeakers: 'Ilona Gulchak, Tom Slapjums',
    activityType: 'Prelekcja'
  },
  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-25T10:45+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Jak uczelnie i instytuty mogą wesprzeć Cię w uruchomieniu własnego biznesu? Transfer technologiczny',
    isBreak: false,
    startTime: '2023-09-25T11:00+02:00',
    panelLength: '60',
    eventSpeakers: 'Paneliści: Judyta Sobczyk, Maciej Klaczyński, Robert Dwiliński, Magdalena Bohusz-Boguszewska | Moderatorka: Anna Zimny-Zając',
    activityType: 'Panel'

  },
  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-20T12:00+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Mosty Innowacji: Współpraca Nauki i Biznesu z perspektywy Doświadczeń Przedsiębiorców, Korporacji i Spółek Skarbu Państwa',
    isBreak: false,
    startTime: '2023-09-25T12:15+02:00',
    panelLength: '70',
    eventSpeakers: 'Paneliści: dr Anna Kowalczuk, Magdalena Tyszkiewicz, Marek Garniewski, Natalia Osica, Mariusz Szmigiel | Moderatorka: Anna Zimny-Zając',
    activityType: 'Panel'

  },
  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2023-09-20T13:25+02:00',
    panelLength: '10',
    icon: 'coffee'
  },
  {
    name: 'Prezentacja naukowa i umiejętność sprzedaży, marketing naukowy jako nowa ścieżka marketingu',
    isBreak: false,
    startTime: '2023-09-25T13:35+02:00',
    panelLength: '75',
    eventSpeakers: 'Bohdan Pawłowicz',
    activityType: 'Warsztat'

  },
  {
    name: 'Przerwa obiadowa',
    isBreak: true,
    startTime: '2023-09-25T14:50+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Historie sukcesów tych, którzy skutecznie połączyli naukę z biznesem',
    isBreak: false,
    startTime: '2023-09-25T15:35+02:00',
    panelLength: '75',
    eventSpeakers: 'Paneliści: Jacek Kosiec, prof. Jacek Jemielity, Stanisław Giziński | Moderator: Przemysława Białkowski',
    activityType: 'Panel'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-25T16:50+02:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const AgendaFSTS = [DayOne, DayTwo]